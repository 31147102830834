<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{
              $t(`categoryModal.category`, {
                expr: editCategory ? "Edit" : "Add",
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`categoryModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs" v-if="editChategory === 'home'">
            <div
              class="col-auto p-0 m-0"
              v-for="l in activeLanguages"
              :key="l.code"
            >
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="changeLanguage(l.code)"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-3 mt-2">
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editChategory = 'home'"
                :class="{
                  'btn-primary': editChategory === 'home',
                  'btn-simple': editChategory !== 'home',
                }"
              >
                {{ $t("account.basics") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editChategory = 'vat'"
                v-if="type === 'category'"
                :class="{
                  'btn-primary': editChategory === 'vat',
                  'btn-simple': editChategory !== 'vat',
                }"
              >
                {{ $t("categoryModal.vat") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editChategory = 'measurement'"
                v-if="type === 'category'"
                :class="{
                  'btn-primary': editChategory === 'measurement',
                  'btn-simple': editChategory !== 'measurement',
                }"
              >
                {{ $t("categoryModal.measurements") }}
              </button>
            </div>
            <div class="col-9 mt-2 text-start custom-input">
              <div class="row" v-if="editChategory === 'home'">
                <div
                  class="col-12 mb-3 whitebg-input"
                  v-if="categories !== undefined"
                >
                  <label class="form-label bold-12 text-gray">
                    {{ $t("categoryModal.mainCategory") }}
                  </label>
                  <v-select
                    v-model="addCategory.parent"
                    :options="categories"
                    :reduce="(a) => a.id"
                    label="name"
                    append-to-body
                  />
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("categoryModal.categoryName") }}
                  </label>
                  <input
                    class="form-control"
                    v-model="addCategory.name[selectedLanguage]"
                  />
                </div>
                <div>
                  <label class="form-label bold-12 text-gray">{{
                    $t("categoryModal.categoryImage")
                  }}</label>
                  <ImageUpload
                    :id="addCategory.id"
                    type="category"
                    v-on:update="uploadImage"
                  />
                  <img
                    v-if="addCategory.image && addCategory.image?.id !== null"
                    class="w-5rem"
                    :src="
                      this.mediaUrl +
                      '/' +
                      addCategory.image.path +
                      '/360-' +
                      addCategory.image.name +
                      '.webp'
                    "
                  />
                  <button
                    class="btn btn-transparent"
                    v-if="addCategory.image && addCategory.image?.id !== null"
                    v-on:click="addCategory.image = null"
                  >
                    <BaseIcon name="trash" />
                  </button>
                </div>
                <div>
                  <label class="form-label bold-12 text-gray">{{
                    $t("categoryModal.categoryIcon")
                  }}</label>
                  <ImageUpload
                    :id="addCategory.id"
                    type="icon"
                    v-on:update="uploadIcon"
                  />
                  <img
                    v-if="addCategory.icon && addCategory.icon?.id !== null"
                    class="w-5rem"
                    :src="
                      this.mediaUrl +
                      '/' +
                      addCategory.icon.path +
                      '/360-' +
                      addCategory.icon.name +
                      '.webp'
                    "
                  />
                  <button
                    class="btn btn-transparent"
                    v-if="addCategory.icon && addCategory.icon?.id !== null"
                    v-on:click="addCategory.icon = null"
                  >
                    <BaseIcon name="trash" />
                  </button>
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("categoryModal.categoryDescription") }}
                  </label>
                  <WYSIWYG
                    v-model="addCategory.description[selectedLanguage]"
                  />
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("categoryModal.categoryTitle") }}
                  </label>
                  <input
                    class="form-control"
                    v-model="addCategory.title[selectedLanguage]"
                  />
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("categoryModal.categoryMeta") }}
                  </label>
                  <textarea
                    v-model="addCategory.short_description[selectedLanguage]"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <div class="row" v-else-if="editChategory === 'vat'">
                <div
                  class="col-12 row align-items-center text-start custom-input"
                  v-for="c in vatRates"
                  :key="c.id"
                >
                  <div class="col-6">
                    {{ c.name }}
                  </div>
                  <div class="col-6">
                    <select
                      class="form-control"
                      v-model="addCategory.vatRates[c.id]"
                    >
                      <option :value="undefined">
                        {{ $t("categoryModal.countryDefault") }}
                      </option>
                      <option
                        :value="rate.id"
                        v-for="rate in c.vatRates"
                        :key="rate.id"
                      >
                        {{ rate.name }} [{{ rate.rate }}%]
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" v-else-if="editChategory === 'measurement'">
                <div v-for="measurement in measurements" :key="measurement.id">
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      measurement.name
                    }}</span>
                    <input
                      type="checkbox"
                      :value="measurement.id"
                      v-model="addCategory.measurements"
                    />
                    <span class="checkmark select mx-2"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="editCategory"
              type="button"
              class="btn btn-primary"
              v-on:click="saveCategory"
            >
              {{ $t("account.save") }}
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              v-on:click="addNewCategory"
            >
              {{ $t("categoryModal.addCategory") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WYSIWYG from "../inputs/WYSIWYG";
import ImageUpload from "../inputs/ImageUpload";
import { defineAsyncComponent } from "vue";
const bootstrap = require("bootstrap");
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));

export default {
  name: "CategoryModal",
  components: { ImageUpload, WYSIWYG, BaseIcon },
  props: {
    editCategory: Boolean,
    vatRates: Object,
    measurements: Array,
    activeLanguages: Array,
    selectedLanguage: String,
    categories: Array,
    type: {
      default: "category",
      type: String,
    },
  },
  emits: [
    "update:selectedLanguage",
    "update:show",
    "addNewCategory",
    "saveCategory",
  ],
  data() {
    return {
      editChategory: "home",
      addCategory: {
        name: {},
        description: {},
        short_description: {},
        title: {},
        measurements: [],
        parent: 0,
      },
    };
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  methods: {
    changeLanguage(code) {
      this.$emit("update:selectedLanguage", code);
    },
    addNewCategory() {
      if (this.addCategory.parent === undefined) {
        this.addCategory.parent = 0;
      }
      this.$emit("addNewCategory", this.addCategory);
    },
    saveCategory() {
      this.$emit("saveCategory", this.addCategory);
    },
    hideModal() {
      this.myModal.hide();
    },
    uploadImage(file) {
      //this.addCategory.image = file.id;
      this.addCategory.image = file;
    },
    uploadIcon(file) {
      //this.addCategory.image = file.id;
      this.addCategory.icon = file;
    },
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
    },
  },
};
</script>
